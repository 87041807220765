import { useMemo } from 'react'
import type { SWRResponse } from 'swr'
import useSWR from 'swr'
import type { UseRetirements } from './types'
import type { RetirementsTrendsTransformed } from '../../../services/transformers/transformRetirementsTrends/types'

/**
 * Attempts to fetch the retirements trends data
 */
export function useRetirementsTrends(
  params: Record<string, unknown> = {},
  groupBy?: string,
): UseRetirements {
  const paramsFormatted = useMemo(() => {
    return {
      ...params,
      groupBy,
    }
  }, [params])

  const { data, isValidating, error, mutate, isLoading } = useSWR<
    RetirementsTrendsTransformed,
    SWRResponse<RetirementsTrendsTransformed>
  >(['/retirementsTrends', undefined, paramsFormatted], {
    onSuccess: (data) => data,
  })

  return {
    data,
    isValidating,
    isLoading,
    error,
    mutate,
    isFetching: isValidating && !data,
  }
}
