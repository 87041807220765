import type { GroupableFieldsKey } from 'types'
import type { TrendsPerformanceCategory } from './types'

export const GROUP_BY_OPTIONS = ['Project types', 'Countries', 'Offset types']

export const GROUP_BY_OPTIONS_TOOLTIPS = {
  'Project types': 'Top 10 project types with the highest volumes',
  Countries: 'Top 10 countries with the highest volumes',
}

export const GROUP_BY_MAPPING: Record<
  TrendsPerformanceCategory,
  GroupableFieldsKey
> = {
  'Project types': 'project.type',
  Countries: 'project.countryName',
  'Offset types': 'project.offsetType',
}

export const LAYOUT_STYLING = {
  padding: 100,
}

export const LABEL_STYLING = {
  borderWidth: 0,
  color: '#01311D',
  font: {
    weight: 'normal',
    size: 14,
  },
  offset: 10,
  padding: 4,
}

export const DISCLAIMER =
  'Contains retirements from 2017 onwards, disclosed on CDP and registries.'
