import { primitives } from '@sylveraio/tailwind'
import type { MapFilter, MapLegendLabel } from './types'
import type { ProfileStatus } from '../../components/CountryMapPopup/types'

export const DATA_MATCHER = 'isoAlpha2Code'

export const NUMBER_OF_STEPS = 8

export const COLORS_FOREST_GREEN = [
  `${primitives['forest'][100]}`,
  `${primitives['forest'][200]}`,
  `${primitives['forest'][300]}`,
  `${primitives['forest'][400]}`,
  `${primitives['forest'][500]}`,
  `${primitives['forest'][600]}`,
  `${primitives['forest'][700]}`,
  `${primitives['forest'][800]}`,
]

export const COLORS_NEON_BLUE = [
  `${primitives['neon-blue'][100]}`,
  `${primitives['neon-blue'][200]}`,
  `${primitives['neon-blue'][300]}`,
  `${primitives['neon-blue'][400]}`,
  `${primitives['neon-blue'][500]}`,
  `${primitives['neon-blue'][600]}`,
  `${primitives['neon-blue'][700]}`,
  `${primitives['neon-blue'][800]}`,
]

export const COLOR_MAPPING_AVERAGE_RATING = [
  [0, `${primitives['off-white-1']}`], // No rating
  [1, `${COLORS_NEON_BLUE[0]}`], // D
  [2, `${COLORS_NEON_BLUE[1]}`], // C
  [3, `${COLORS_NEON_BLUE[2]}`], // B
  [4, `${COLORS_NEON_BLUE[3]}`], // BB
  [5, `${COLORS_NEON_BLUE[4]}`], // BBB
  [6, `${COLORS_NEON_BLUE[5]}`], // A
  [7, `${COLORS_NEON_BLUE[6]}`], // AA
  [8, `${COLORS_NEON_BLUE[7]}`], // AAA
]

export const COLORS: Record<MapFilter, Array<string>> = {
  averageRatingNumeric: COLORS_NEON_BLUE,
  allProjectsCount: COLORS_FOREST_GREEN,
  estimatedCreditsSupply: COLORS_FOREST_GREEN,
}

export const DEAFULT_DATA = {
  allProjectsCount: 0,
  averageRatingNumeric: 0,
  estimatedCreditsSupply: 0,
}

export const FILTER_LEGEND_LABEL_MAPPING: Record<MapFilter, MapLegendLabel> = {
  averageRatingNumeric: 'Avg. Rating',
  allProjectsCount: 'Project Count',
  estimatedCreditsSupply: 'Est. Supply',
}

export const ANALYTIC_EVENTS_MAPPING: Record<ProfileStatus, string> = {
  locked: 'country-map-tile-request-access-profile-click',
  available: 'country-map-tile-open-profile-click',
  pending: 'country-map-tile-request-profile-click',
}
