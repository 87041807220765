import { type FC, Suspense, useRef, useState } from 'react'
import { useRouter } from 'next/navigation'
import {
  sendAnalyticsEvent,
  useClickOutsideElement,
} from '@sylveraio/react-utils'
import type { MarketCommentaryTileProps } from './types'
import { TileFilters } from '../../components/TileFilters'
import { CustomiseModal } from '../PolicyArticle'
import { SelectedPolicyFilters, usePreferences } from '@sylveraio/data'
import { CustomiseModalButton } from '../PolicyArticle'
import {
  MarketCommentaryCTA,
  MarketCommentaryCTATitle,
  MarketCommentaryTileTagFilterForYou,
  MarketCommentaryTileTagFilters,
  PATHNAME,
} from './constants'
import { MarketCommentaryTileArticles } from './MarketCommentaryTileArticles'
import { NewsListCTA, Spinner } from '@sylveraio/design-system'
import { DashboardTileWrapper } from '../../components/DashboardTileWrapper'

export const MarketCommentaryTile: FC<MarketCommentaryTileProps> = ({
  articles,
  filterValues,
  id,
}) => {
  const router = useRouter()

  const filterRef = useRef(null)
  const [selectedFilter, setSelectedFilter] = useState(
    MarketCommentaryTileTagFilterForYou,
  )

  const [showCustomisationModal, setShowCustomisationModal] =
    useState<boolean>(false)

  const { data: policyFiltersPreference, upsertPreference } = usePreferences(
    'policy_article_filters',
  )

  const handleSeeAllClick = () => {
    sendAnalyticsEvent({
      eventName: 'market-commentary-tile-see-all',
    })
  }

  const handleSelectedTagFilter = (filter: string) => {
    sendAnalyticsEvent({
      eventName: 'market-commentary-tile-filter',
      eventData: {
        filter,
      },
    })
    setSelectedFilter(filter)
  }

  const handleEmptyStateClick = () => {
    setShowCustomisationModal(true)
  }

  const handleNewsListCTA = () => {
    sendAnalyticsEvent({
      eventName: 'market-commentary-tile-cta',
    })
    router.push('/market-commentary')
  }

  const handleCustomisationFilterChange = (
    changeSet: SelectedPolicyFilters,
  ) => {
    if (upsertPreference) {
      setShowCustomisationModal(false)
      upsertPreference(changeSet)
    }

    sendAnalyticsEvent({
      eventName: 'market-commentary-tile-customise-filters-update',
      eventData: {
        customisedFeedFilters: changeSet,
      },
    })
  }

  const handleToggleCustomisationFilter = () => {
    setShowCustomisationModal(!showCustomisationModal)
    sendAnalyticsEvent({
      eventName: 'market-commentary-tile-customise-filters-open',
    })
  }

  useClickOutsideElement(
    filterRef,
    () => {
      setShowCustomisationModal(false)
    },
    showCustomisationModal,
    true,
  )

  return (
    <DashboardTileWrapper
      title="Market Commentary"
      width="50%"
      key="tile-market-commentary"
      testId="tile-market-commentary"
      href={PATHNAME}
      onSeeAllClick={handleSeeAllClick}
      id={id}
      appName="market-commentary"
    >
      <div className="flex items-center justify-between bg-high sticky top-0 z-10">
        <TileFilters
          filterClassName="py-4"
          filters={MarketCommentaryTileTagFilters}
          onFilter={handleSelectedTagFilter}
          selected={selectedFilter}
          testId="tile-market-commentary-filters"
          ignoreDisabled
        />
        {selectedFilter === MarketCommentaryTileTagFilterForYou && (
          <div ref={filterRef} className="relative">
            <CustomiseModalButton onClick={handleToggleCustomisationFilter} />
            <CustomiseModal
              selectedFilters={policyFiltersPreference?.value}
              onSave={handleCustomisationFilterChange}
              isOpen={showCustomisationModal}
              filterValues={filterValues}
            />
          </div>
        )}
      </div>
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-full">
            <Spinner />
          </div>
        }
      >
        <div className="overflow-auto max-h-[450px] flex flex-col justify-between h-full">
          <MarketCommentaryTileArticles
            articles={articles}
            handleEmptyState={handleEmptyStateClick}
            selectedFilters={policyFiltersPreference?.value}
            selectedFilter={selectedFilter}
          />
          {
            <NewsListCTA
              title={MarketCommentaryCTATitle}
              callToAction={MarketCommentaryCTA}
              onClick={handleNewsListCTA}
              img={{
                src: '/images/market-commentary-cta.png',
                alt: 'news-list-cta-image',
                width: 92,
                height: 72,
              }}
            />
          }
        </div>
      </Suspense>
    </DashboardTileWrapper>
  )
}
