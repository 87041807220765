import { Main, UpsellModal } from '@sylveraio/layouts'
import Head from 'next/head'
import { HubspotStateContext } from '@sylveraio/auth'
import {
  withAuth,
  getAppStates,
  HubspotFormVariant,
} from '@sylveraio/auth-utils'
import { useUser } from '@sylveraio/data'
import { useContext, useMemo } from 'react'
import type { AppData, AppName, KVPairs } from 'types'
import {
  getS3Object,
  useCheckJreddAccess,
  captureException,
  sortByProperty,
} from '@sylveraio/react-utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Greeting } from '../containers/Greeting'
import { DashboardWrapper } from '../containers/DashboardTiles'
import type { DashboardWrapperProps } from '../containers/DashboardTiles/types'
import {
  MARKET_COMMENTARY_REVALIDATION_SECONDS,
  TILE_TOOLTIP_MSG_MAP,
} from '../constants'
import {
  BUCKET_NAME_CMS_DATA,
  TILE_CONTAINER_COMPONENT_MAP,
} from '../constants'
import { TileImage } from '../components/TileImage'
import { MarketCommentaryTile } from '../containers/MarketCommentaryTile'
import { RetirementsTrendsTile } from '../containers/RetirementsTrendsTile'
import { MarketCommentaryArticle } from '../containers/MarketCommentaryArticle'
import { useSearchParams } from 'next/navigation'
import type { GetStaticProps, InferGetStaticPropsType } from 'next'
import type { Policy, PolicyTag } from '../helpers/api/types'
import flatten from 'lodash/flatten'
import { CountryMapTile } from '../containers/CountryMapTile'
import { apolloClient as client } from '../libs/graphQLClient'
import { GET_MARKET_COMMENTARY_TILE_ARTICLES } from '../containers/MarketCommentaryTile/queries'
import type { PolicyArticleEntry } from '../containers/PolicyArticle/types'
import { CountryRiskTile } from '../containers/CountryRiskTile'
import { RequestMoreInfoForm } from '../components/RequestMoreInfoForm'
import { RequestCountryAssessmentForm } from '../components/RequestCountryAssessmentForm'

const newImageUrls: Partial<
  Record<AppName, { url: string; width: number; height: number }>
> = {
  insights: {
    url: '/assets/insights.png',
    width: 899,
    height: 743,
  },
  box: {
    url: '/assets/jurisdictional–intel.png',
    width: 596,
    height: 516,
  },
}

interface Props {
  subheader: { text: string } | null
  policyArticles: PolicyArticleEntry[] | null
  policyTags: KVPairs | null
  countries: KVPairs | null
  projectTypes: KVPairs | null
  policies: Policy[] | null
  tags: PolicyTag[] | null
}

export const getStaticProps = (async () => {
  const props: Props = {
    subheader: null,
    policyArticles: null,
    policyTags: null,
    countries: null,
    projectTypes: null,
    policies: null,
    tags: null,
  }

  try {
    const subheaderData = await getS3Object<{ text: string }>(
      BUCKET_NAME_CMS_DATA,
      'subheader/subheader.json',
    )
    props.subheader = subheaderData
  } catch (e) {
    captureException(e, 'dashboard - pages - index - subheader s3')
  }

  // Attempt to fetch Contentful data
  if (
    typeof process.env.NEXT_PUBLIC_CONTENTFUL_SPACE !== 'undefined' &&
    typeof process.env.NEXT_PUBLIC_POLICY_TOKEN !== 'undefined'
  ) {
    try {
      const { data } = await client.query({
        query: GET_MARKET_COMMENTARY_TILE_ARTICLES,
        fetchPolicy: 'no-cache',
      })

      const sortedTags = sortByProperty(
        data?.policyArticleTagCollection?.items,
        'tagText',
      )
      const sortedCountries = sortByProperty(
        data?.countryTagCollection?.items,
        'country',
      )
      const sortedProjectTypes = sortByProperty(
        data?.projectTypeTagCollection?.items,
        'projectTypeText',
      )

      // Update props with Contentful data if available
      props.policyArticles = data?.policyArticleCollection?.items
      props.policyTags = (sortedTags || []).map(({ tagText, tagTextId }) => ({
        id: tagTextId,
        value: tagText,
      }))
      props.countries = (sortedCountries || []).map(
        ({ country, countryId }) => ({ id: countryId, value: country }),
      )
      props.projectTypes = (sortedProjectTypes || []).map(
        ({ projectTypeText, projectTypeId }) => ({
          id: projectTypeId,
          value: projectTypeText,
        }),
      )
    } catch (e) {
      captureException(e, 'dashboard - pages - index - contentful')
    }
  }

  return {
    props,
    revalidate: MARKET_COMMENTARY_REVALIDATION_SECONDS,
  }
}) satisfies GetStaticProps<Props>

export function Index({
  subheader,
  policyArticles,
  policyTags = [],
  countries = [],
  projectTypes = [],
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { showCountryMapTile = false } = useFlags()
  const { data, hasData, isFetching } = useUser()
  const canAccessJredd = useCheckJreddAccess(data?.organisationId)
  const searchParams = useSearchParams()

  const policyArticleParam = searchParams?.get('policyArticle')

  const appUrls = getAppStates(data?.appCapabilities, {
    excludeApps: ['user-management', 'dashboard', 'analytics'],
    hasJREDDAccess: canAccessJredd,
    isFetching: !hasData,
  })

  const {
    hubspotAppName,
    hubspotFormCustomOverride,
    resetHubspotData,
    setHubspotData,
  } = useContext(HubspotStateContext)

  const handleLockClick = (
    appName: AppName,
    additionalFields?: Record<string, string>,
    variant?: HubspotFormVariant,
  ) => {
    setHubspotData(appName, {
      variant: variant ?? 'app',
      data: {
        userOrganisation: data?.organisation,
        userFirstName: data?.givenName,
        userLastName: data?.familyName,
        userEmail: data?.email,
      },
      additionalData: { ...additionalFields },
    })
  }

  const tiles: DashboardWrapperProps['tiles'] = useMemo(() => {
    return flatten([
      Object.entries(appUrls)
        // @ts-ignore
        .map(([name, data]: [AppName, AppData]) => {
          const { url = '' } = newImageUrls[name] || {}
          const id = `tile-${name}`
          return {
            id,
            props: {
              id,
              appName: name,
              href: data.state === 'active' ? data.url : undefined,
              width: '50%',
              title: data.title,
              subTitle: data.subTitle,
              children: (name === 'ratings' &&
                TILE_CONTAINER_COMPONENT_MAP?.[name]) || (
                <TileImage url={url} alt={`Go to ${name}`} />
              ),
              locked: data.state === 'locked',
              testId: id,
              tooltip: TILE_TOOLTIP_MSG_MAP[name] as string,
              onUnlock: (appName: AppName) => handleLockClick(appName),
            },
          }
        }),
      {
        id: 'tile-market-commentary',
        component: (
          <MarketCommentaryTile
            articles={policyArticles}
            filterValues={{
              countries,
              projectTypes,
              policyTags,
            }}
            key="tile-market-commentary"
            id="tile-market-commentary"
          />
        ),
      },
      {
        id: 'tile-retirements-trends',
        component: (
          <RetirementsTrendsTile
            locked={!data?.appCapabilities.includes('analytics') && !isFetching}
            onUnlock={() => handleLockClick('analytics')}
            id="tile-retirements-trends"
            key="tile-retirements-trends"
          />
        ),
      },
      showCountryMapTile && {
        id: 'tile-countries-map',
        component: (
          <CountryMapTile
            id="tile-countries-map"
            key="tile-countries-map"
            onUnlock={handleLockClick}
          />
        ),
      },
      showCountryMapTile && {
        id: 'tile-countries-risk-table',
        component: (
          <CountryRiskTile
            id="tile-countries-risk-table"
            key="tile-countries-risk-table"
            onUnlock={handleLockClick}
          />
        ),
      },
    ]).filter(Boolean)
  }, [appUrls, showCountryMapTile])

  return (
    <>
      <Head key="page-dashboard">
        <title>Sylvera | Dashboard</title>
      </Head>
      <Main>
        <Greeting subheader={subheader} />
        <DashboardWrapper tiles={tiles} />
      </Main>
      {(hubspotFormCustomOverride?.variant === 'project' ||
        hubspotFormCustomOverride?.variant === 'tier') && (
        <UpsellModal
          formCustomOverride={hubspotFormCustomOverride}
          appName={hubspotAppName}
          onClose={resetHubspotData}
          show={
            typeof hubspotAppName !== 'undefined' ||
            typeof hubspotFormCustomOverride !== 'undefined'
          }
        />
      )}
      {(hubspotFormCustomOverride?.variant === 'app' ||
        hubspotFormCustomOverride?.variant === 'country') &&
        hubspotAppName !== null &&
        typeof hubspotFormCustomOverride !== 'undefined' && (
          <RequestMoreInfoForm
            formData={hubspotFormCustomOverride}
            appName={hubspotAppName}
            onClose={resetHubspotData}
          />
        )}
      {hubspotFormCustomOverride?.variant === 'countryAssessment' &&
        typeof hubspotFormCustomOverride !== 'undefined' && (
          <RequestCountryAssessmentForm
            formData={hubspotFormCustomOverride}
            onClose={resetHubspotData}
          />
        )}
      {policyArticleParam && (
        <MarketCommentaryArticle id={policyArticleParam} />
      )}
    </>
  )
}

export default withAuth(Index)
