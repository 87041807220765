import { type TypedDocumentNode, gql } from '@apollo/client'
import { type CountryRisksInbound } from './countryProfiles/types'

export const GET_COUNTRY_RISKS: TypedDocumentNode<
  CountryRisksInbound,
  {
    limit: number
  }
> = gql`
  query GetCountryProfileTileRisk($limit: Int!) {
    profileCollection(limit: $limit) {
      items {
        __typename
        title
        profileId
        blocksCollection(limit: 10) {
          items {
            ... on RiskBlock {
              __typename
              riskContainersCollection(limit: 10) {
                items {
                  riskType
                  riskLevel
                }
              }
            }
          }
        }
      }
    }
  }
`
