import type { CountryProfileInbound, CountryProfilesInbound } from 'types'
import type { CountryProfile } from './types'

export function transformCountryProfiles(
  data?: CountryProfilesInbound,
): Array<CountryProfile> | undefined {
  return data?.countryProfiles?.nodes?.map((node: CountryProfileInbound) => {
    const issuance = node?.projectAggregates?.issuance
    const sylveraRating = node?.projectAggregates?.sylveraRating
    const estimatedCreditsSupply = issuance?.estimatedCreditsSupply?.sum

    return (
      {
        name: node?.country?.name,
        allProjectsCount: node?.projectAggregates?.count,
        isoAlpha2Code: node?.country?.isoAlpha2Code,
        estimatedCreditsSupply: estimatedCreditsSupply,
        permittedCredits: issuance?.permittedCredits?.sum,
        retiredCredits: issuance?.retiredCredits?.sum,
        cancelledCredits: issuance?.cancelledCredits?.sum,
        sylveraRatingAverage: sylveraRating?.average,
        ratedProjectsCount: sylveraRating?.count,
      } || []
    )
  })
}
