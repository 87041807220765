import { useState, useEffect, type FC } from 'react'
import {
  ANALYTIC_EVENTS_MAPPING,
  COLORS,
  COLOR_MAPPING_AVERAGE_RATING,
  DATA_MATCHER,
  DEAFULT_DATA,
  FILTER_LEGEND_LABEL_MAPPING,
  NUMBER_OF_STEPS,
} from './constants'
import geoJson from '../../public/maps/countriesGeo.json'
import {
  getChoroplethColors,
  mergeGeoJsonWithData,
  sendAnalyticsEvent,
} from '@sylveraio/react-utils'
import type { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson'
import type { CountryMapProps } from './types'
import { ChoroplethMap, GradientLegend } from '@sylveraio/react-ui'
import { useValuesForMapLegend } from '../../utils/hooks/useValuesForMapLegend'
import { CountryMapPopup } from '../../components/CountryMapPopup'
import millify from 'millify'
import type { CountryMapRecord } from '../../utils/hooks/useMapData/types'
import { useMapData } from '../../utils/hooks/useMapData'
import { Spinner } from '@sylveraio/design-system'
import type { ProfileStatus } from '../../components/CountryMapPopup/types'

export const CountryMap: FC<CountryMapProps> = ({
  token,
  filter = 'averageRatingNumeric',
  onUnlock,
}) => {
  const { data, isFetching } = useMapData()
  const [mapData, setMapData] = useState<FeatureCollection<Geometry>>()
  const [colorMapping, setColorMapping] = useState(COLOR_MAPPING_AVERAGE_RATING)
  const [lowestValue, highestValue] = useValuesForMapLegend(data, filter, true)

  useEffect(() => {
    if (filter === 'averageRatingNumeric') {
      setColorMapping(COLOR_MAPPING_AVERAGE_RATING)
      return
    }

    setColorMapping(
      getChoroplethColors(data, filter, NUMBER_OF_STEPS, COLORS[filter]),
    )
  }, [filter])

  useEffect(() => {
    if (typeof data === 'undefined' || isFetching) return

    const dataMerged = mergeGeoJsonWithData(
      geoJson as FeatureCollection<Geometry>,
      data,
      DEAFULT_DATA,
      DATA_MATCHER,
    )
    setMapData(dataMerged)
  }, [isFetching])

  const handlePopupButtonClick = (
    isoAlpha2Code?: string,
    profileStatus?: ProfileStatus,
    countryName?: string,
  ) => {
    if (profileStatus) {
      sendAnalyticsEvent({
        eventName: ANALYTIC_EVENTS_MAPPING[profileStatus],
        eventData: {
          countryName,
        },
      })
    }
    if (profileStatus === 'locked' && countryName) {
      onUnlock?.(
        'country-profiles',
        {
          countryProfileName: countryName,
        },
        'country',
      )
      return
    }

    if (profileStatus === 'pending' && countryName) {
      onUnlock?.(
        'country-profiles',
        {
          countryProfileName: countryName,
        },
        'countryAssessment',
      )
      return
    }

    if (!isoAlpha2Code) return
    window.open(`profile/country/${isoAlpha2Code}`, '_blank')
  }
  const renderPopupContent = (countryObject: GeoJsonProperties) => {
    return (
      <CountryMapPopup
        countryData={countryObject as CountryMapRecord}
        onClick={handlePopupButtonClick}
      />
    )
  }

  const getLegendComponent = (): JSX.Element => (
    <GradientLegend
      title={FILTER_LEGEND_LABEL_MAPPING[filter]}
      colors={COLORS[filter]}
      lowestValue={
        typeof lowestValue === 'number' ? millify(lowestValue) : lowestValue
      }
      highestValue={
        typeof highestValue === 'number' ? millify(highestValue) : highestValue
      }
    />
  )

  if (typeof mapData === 'undefined')
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner />
      </div>
    )

  return (
    <ChoroplethMap
      data={mapData}
      token={token}
      filter={filter}
      colorScaleForData={colorMapping}
      renderPopupContent={renderPopupContent}
      legendComponent={getLegendComponent()}
      tooltipConditionProperty={filter}
    />
  )
}
