import { useMemo } from 'react'

/**
 * Takes specified parameters and outputs an array of min and max values
 * @param data data object
 * @param dataParameter field the min and max values will be created for
 * @param useZeroAsMinValue should the hook return 0 for the min value
 * @returns an array with min and max value
 */

export function useValuesForMapLegend<
  T extends Record<string, number | string | boolean | undefined>,
>(
  data: T[] = [],
  dataParameter: string,
  useZeroAsMinValue?: boolean,
): [number | string, number | string] {
  return useMemo(() => {
    if (dataParameter === 'averageRatingNumeric') {
      return ['D', 'AAA']
    }

    if (typeof dataParameter !== 'undefined' && typeof data !== 'undefined') {
      const values = data

        .map((item) => item?.[dataParameter])
        .filter(
          (value) => value !== 0 && typeof value === 'number',
        ) as Array<number>
      if (values.length === 0) return [0, 100]
      const min = useZeroAsMinValue ? 0 : Math.min(...values)
      const max = Math.max(...values)
      return [min, max]
    }

    return [0, 100]
  }, [data, dataParameter])
}

export default useValuesForMapLegend
