import type { FC } from 'react'
import type { TileContainerProps } from './types'
import { Spinner } from '@sylveraio/design-system'

export const TileContainer: FC<TileContainerProps> = ({
  tiles,
  isFetching,
}) => {
  if (isFetching)
    return (
      <div className="flex items-center justify-center w-full min-h-96">
        <Spinner />
      </div>
    )

  return <ul className="grid w-full grid-cols-2 gap-x-10 gap-y-10">{tiles}</ul>
}
