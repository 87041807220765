import {
  HubspotFormCustomOverrideData,
  submitHubspotForm,
  type HubspotFormData,
} from '@sylveraio/auth-utils'

export const submitDashboardTilesHubspotForm = async (
  user: HubspotFormCustomOverrideData,
  formId: string,
  additionalFields?: Record<string, string>,
) => {
  const hubspotFormData: HubspotFormData = [
    {
      name: 'firstname',
      value: user.userFirstName || '',
    },
    {
      name: 'lastname',
      value: user.userLastName || '',
    },
    {
      name: 'email',
      value: user.userEmail || '',
    },
    {
      name: 'company',
      value: user.userOrganisation || '',
    },
  ]

  if (additionalFields) {
    Object.entries(additionalFields).forEach(([key, value]) => {
      hubspotFormData.push({ name: key, value: value })
    })
  }

  return submitHubspotForm({
    id: formId,
    data: hubspotFormData,
  })
}
