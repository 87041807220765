export const UI_STRINGS = {
  title: 'Request Country Profile',
  notificationPartOne: 'You are requesting a profile for',
  notificationPartTwo:
    ', this request will be added to our Country Profile backlog.',
  disclaimer:
    'The more users that request a profile the higher up our backlog it will be. For more information please reach out to your customer success manager.',
  buttonCancel: 'Cancel',
  buttonSubmitting: 'Submitting ...',
  buttonSubmit: 'Submit request',
}
