const CELL_WIDTH = 'w-[85px]'
const HEADER_STYLE = 'justify-center text-xs pb-3'
const CELL_STYLE = 'text-center'

export const COLUMNS_STYLING = {
  countryName: {
    th: {
      outer: CELL_WIDTH,
      inner: 'text-xs pb-3',
    },
    td: {
      inner: 'text-md font-semibold',
    },
  },
  deliveryRisk: {
    th: {
      outer: CELL_WIDTH,
      inner: HEADER_STYLE,
    },
    td: {
      outer: CELL_STYLE,
    },
  },
  utilityRisk: {
    th: {
      outer: CELL_WIDTH,
      inner: HEADER_STYLE,
    },
    td: {
      outer: CELL_STYLE,
    },
  },
  reputationalRisk: {
    th: {
      outer: CELL_WIDTH,
      inner: HEADER_STYLE,
    },
    td: {
      outer: CELL_STYLE,
    },
  },
  costRisk: {
    th: {
      outer: CELL_WIDTH,
      inner: HEADER_STYLE,
    },
    td: {
      outer: CELL_STYLE,
    },
  },
}
