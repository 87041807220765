import { type TypedDocumentNode, gql } from '@apollo/client'
import { type CountryProfilesInbound } from 'types'

export const GET_COUNTRY_PROFILES: TypedDocumentNode<
  CountryProfilesInbound,
  {
    first: number
  }
> = gql`
  query GetCountryProfiles($first: Int!) {
    countryProfiles(first: $first) {
      nodes {
        id
        country {
          name
          isoAlpha2Code
        }
        projectAggregates {
          count
          issuance {
            estimatedCreditsSupply {
              sum
            }
            permittedCredits {
              sum
            }
            retiredCredits {
              sum
            }
            cancelledCredits {
              sum
            }
          }
          sylveraRating {
            average
            count
          }
        }
      }
    }
  }
`
