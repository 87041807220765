export const MarketCommentaryTileTagFilterForYou = 'For you'
export const MarketCommentaryTileTagFilterMostRecent = 'Most recent'
export const MarketCommentaryTileTagFilters = [
  MarketCommentaryTileTagFilterForYou,
  MarketCommentaryTileTagFilterMostRecent,
]
export const MarketCommentaryCTATitle =
  'Read Sylvera’s take across key topics and browse all market headlines'
export const MarketCommentaryCTA = 'Discover more Market Commentary'
export const PATHNAME = '/market-commentary'
