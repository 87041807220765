import type { FC } from 'react'

import type { CountryProfilesLockedModalProps } from './types'
import { Label, Modal } from '@sylveraio/design-system'
import { ADD_ON, MODAL_BUTTON, TITLE, SUBMITTING } from './constants'
import { CountryProfilesLockedContent } from '../CountryProfilesLockedContent'
import Lightning01 from '@sylveraio/untitled-ui-icons-react/build/esm/Lightning01'
import { INTRODUCTION } from '../CountryProfilesLockedContent/constants'

export const CountryProfilesLockedModal: FC<
  CountryProfilesLockedModalProps
> = ({
  testId = 'country-profiles-locked-modal',
  isSubmitting,
  onClose,
  onSubmit,
}) => (
  <Modal
    title={TITLE}
    subtitle={INTRODUCTION}
    titleLabel={
      <Label label={ADD_ON} outlined iconLeading={<Lightning01 />} strong />
    }
    size="md"
    onClose={onClose}
    primaryActionProps={{
      label: isSubmitting ? SUBMITTING : MODAL_BUTTON,
      variant: 'primary',
      onClick: onSubmit,
      disabled: isSubmitting,
    }}
    secondaryActionProps={{
      label: 'Cancel',
      variant: 'secondary',
      onClick: onClose,
    }}
    testId={testId}
  >
    <CountryProfilesLockedContent hideIntro />
  </Modal>
)
