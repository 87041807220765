import { useQuery } from '@apollo/client'
import { GET_COUNTRY_PROFILES } from '../../../queries/getCountryProfiles'
import { transformCountryProfiles } from '../../transformCountryProfiles'
import type { UseCountryProfiles } from './types'
import { DEFAULT_NUM_COUNTRIES_REQUEST } from './constants'

export const useCountryProfiles = (): UseCountryProfiles => {
  const { data, error, loading } = useQuery(GET_COUNTRY_PROFILES, {
    variables: {
      first: DEFAULT_NUM_COUNTRIES_REQUEST,
    },
    // indicates which api to use
    context: { apiName: 'sylvera' },
  })
  const transformedData = data ? transformCountryProfiles(data) : undefined

  return {
    data: transformedData,
    error,
    loading: loading && !data,
  }
}
