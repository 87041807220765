import { type FC, useMemo } from 'react'
import type { Row } from '@tanstack/react-table'
import { Typography, type TableColumn } from '@sylveraio/design-system'
import { RiskLabel } from '../../../components/RiskLabel'
import type { RiskLevelsNumeric } from '../../../components/RiskTable/types'
import { RiskTableHeader } from '../../../components/RiskTableHeader'
import ChevronRight from '@sylveraio/untitled-ui-icons-react/build/esm/ChevronRight'
import Lock01 from '@sylveraio/untitled-ui-icons-react/build/esm/Lock01'
import clsx from 'clsx'
import type { RiskLevels } from '../../../components/RiskLabel/types'
import type { TransformedCountryRisk } from '../../transformCountryRisks/types'

const RiskCellContent: FC<{
  riskLevel?: RiskLevelsNumeric
  locked?: boolean
}> = ({ riskLevel, locked }) => {
  if (!riskLevel || locked) return null

  return <RiskLabel riskLevel={riskLevel.toString() as RiskLevels} />
}

export function useRiskTableColumns(): TableColumn<TransformedCountryRisk>[] {
  const defaultColumns: TableColumn<TransformedCountryRisk>[] = useMemo(
    () => [
      {
        datasetKey: '_id',
        title: 'id',
      },
      {
        datasetKey: '_locked',
        title: '_locked',
        hideColumn: true,
      },
      {
        datasetKey: 'countryName',
        title: 'Country',
        sortable: true,
        defaultSortDirection: 'asc',
        sortingFn: 'customSortingWithLocked',
        Cell: ({ row }: { row: Row<TransformedCountryRisk> }) => {
          return (
            <div className="flex">
              {row?.original?._locked && (
                <Lock01 className="w-4 translate-y-0.5 mr-0.5 text-subtle" />
              )}
              <Typography
                size="sm"
                weight="semibold"
                className={clsx({ 'text-subtle': row?.original?._locked })}
              >
                {row?.original?.countryName}
              </Typography>
            </div>
          )
        },
      },
      {
        datasetKey: 'deliveryRisk',
        header: () => <RiskTableHeader risk="deliveryRisk" />,
        sortable: true,
        defaultSortDirection: 'asc',
        Cell: ({ row }: { row: Row<TransformedCountryRisk> }) => {
          return (
            <RiskCellContent
              riskLevel={row?.original?.deliveryRisk}
              locked={row?.original?._locked}
            />
          )
        },
      },
      {
        datasetKey: 'utilityRisk',
        header: () => <RiskTableHeader risk="utilityRisk" />,
        sortable: true,
        defaultSortDirection: 'asc',
        Cell: ({ row }: { row: Row<TransformedCountryRisk> }) => {
          return (
            <RiskCellContent
              riskLevel={row?.original?.utilityRisk}
              locked={row?.original?._locked}
            />
          )
        },
      },
      {
        datasetKey: 'reputationalRisk',
        header: () => <RiskTableHeader risk="reputationalRisk" />,
        sortable: true,
        defaultSortDirection: 'asc',
        Cell: ({ row }: { row: Row<TransformedCountryRisk> }) => {
          return (
            <RiskCellContent
              riskLevel={row?.original?.reputationalRisk}
              locked={row?.original?._locked}
            />
          )
        },
      },
      {
        datasetKey: 'costRisk',
        header: () => <RiskTableHeader risk="costRisk" />,
        showHeaderTooltip: true,
        sortable: true,
        defaultSortDirection: 'asc',
        Cell: ({ row }: { row: Row<TransformedCountryRisk> }) => {
          return (
            <RiskCellContent
              riskLevel={row?.original?.costRisk}
              locked={row?.original?._locked}
            />
          )
        },
      },
      {
        datasetKey: 'viewProfileButton',
        size: 40,
        showCellContentOnHover: true,
        Cell: ({ row }) => {
          return row?.original?._locked ? null : (
            <ChevronRight className="w-5" />
          )
        },
      },
    ],
    [],
  )

  return defaultColumns
}

export default useRiskTableColumns
