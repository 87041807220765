import type { FC } from 'react'

import type { FindOutMoreProps } from './types'
import { RequestMoreInfoButton, Typography } from '@sylveraio/design-system'
import { CONTENT, TITLE } from './constants'

export const FindOutMore: FC<FindOutMoreProps> = ({
  testId = 'find-out-more',
  onClick,
}) => (
  <div
    className="flex p-3 bg-low border border-default gap-6 w-full justify-between items-center rounded-lg"
    data-testid={testId}
  >
    <div className="flex-col">
      <Typography size="sm" weight="semibold">
        {TITLE}
      </Typography>
      <Typography size="xs" className="text-subtle">
        {CONTENT}
      </Typography>
    </div>
    <RequestMoreInfoButton onClick={onClick} />
  </div>
)
