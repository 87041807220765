import type { FC } from 'react'
import { Typography } from '@sylveraio/design-system'
import { INTRODUCTION, PRODUCT_OFFERINGS_SUMMARY } from './constants'
import { Carousel } from './Carousel'

export const CountryProfilesLockedContent: FC<{
  testId?: string
  hideIntro?: boolean
}> = ({ testId = 'country-profiles-locked-content', hideIntro }) => (
  <div data-testid={testId}>
    <div className="flex flex-col gap-y-3">
      {!hideIntro && (
        <div className="border-b border-b-default pb-2">
          <Typography className="text-subtle" size="sm">
            {INTRODUCTION}
          </Typography>
        </div>
      )}
      <div className="flex flex-row space-x-2">
        <div className="flex flex-col w-full desktop-md:w-1/2 gap-y-3">
          {PRODUCT_OFFERINGS_SUMMARY.map((section) => {
            return (
              <div
                key={section.title}
                className="flex flex-row justify-start space-x-3"
              >
                <div data-testid={`${testId}-icon`}>
                  <section.icon className="w-5 h-5 translate-y-0.5" />
                </div>
                <div className="flex flex-col">
                  <Typography size="sm" weight="medium">
                    {section.title}
                  </Typography>
                  <Typography size="sm" className="text-subtle">
                    {section.description}
                  </Typography>
                </div>
              </div>
            )
          })}
        </div>
        <div className="hidden desktop-md:flex w-1/2">
          <div className="rounded-lg overflow-hidden w-[306px]">
            <Carousel />
          </div>
        </div>
      </div>
    </div>
  </div>
)
