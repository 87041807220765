import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import type { Components } from 'react-markdown'
import { useUser } from '@sylveraio/data'
import type { GreetingProps } from './types'

const components: Components = {
  a: ({ href, children }) => (
    <a
      data-testid="markdown-renderer-anchor"
      className="underline-outset-1 no-underline hover:underline"
      href={href}
      target={href?.charAt(0) !== '#' ? '_blank' : undefined}
      rel="noreferrer"
    >
      {children}
    </a>
  ),
}

export const Greeting: FC<GreetingProps> = ({ subheader }) => {
  const { data } = useUser()

  if (!data) return <section className="h-16" />

  return (
    <section className="mb-8 h-16 space-y-1.5 !text-default">
      <h1 className="text-3xl">Welcome back, {data?.givenName}</h1>
      {subheader && (
        <ReactMarkdown className="text-sm" components={components}>
          {subheader?.text}
        </ReactMarkdown>
      )}
    </section>
  )
}
