import type { FC } from 'react'
import type { RequestAssessmentModalProps } from './types'
import { Modal, Typography } from '@sylveraio/design-system'
import { UI_STRINGS } from './constants'

export const RequestAssessmentModal: FC<RequestAssessmentModalProps> = ({
  testId = 'request-assessment-modal',
  countryName,
  isSubmitting,
  onSubmit,
  onClose,
}) => (
  <Modal
    testId={testId}
    title={UI_STRINGS.title}
    size="sm"
    onClose={onClose}
    primaryActionProps={{
      label: isSubmitting
        ? UI_STRINGS.buttonSubmitting
        : UI_STRINGS.buttonSubmit,
      variant: 'primary',
      onClick: onSubmit,
      disabled: isSubmitting,
    }}
    secondaryActionProps={{
      label: UI_STRINGS.buttonCancel,
      variant: 'secondary',
      onClick: onClose,
    }}
  >
    <div className="flex flex-col space-y-2">
      <Typography size="base">
        {`${UI_STRINGS.notificationPartOne} ${countryName}${UI_STRINGS.notificationPartTwo}`}
      </Typography>
      <Typography size="base" className="text-subtle">
        {UI_STRINGS.disclaimer}
      </Typography>
    </div>
  </Modal>
)
