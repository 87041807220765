import type {
  CountryRisksInbound,
  ProfileBlocks,
  RiskContainer,
} from '../../queries/countryProfiles/types'
import type { LockedState, TransformedCountryRisk } from './types'

export function transformCountryRisks(
  data?: CountryRisksInbound,
): Array<TransformedCountryRisk> | LockedState | undefined {
  const items = data?.profileCollection?.items

  // If there are no items returned from the response, the user does not have permission to view any country profiles.
  if (items === null) {
    return { _locked: true }
  }

  const transformedData = items?.map((node) => {
    const riskBlock = node.blocksCollection?.items.find(
      (
        block,
      ): block is ProfileBlocks & {
        riskContainersCollection: { items: RiskContainer[] }
      } => block.__typename === 'RiskBlock',
    )

    const risks = riskBlock?.riskContainersCollection?.items

    const riskProperties =
      risks?.reduce((acc, risk) => {
        acc[`${risk.riskType}Risk`] = risk.riskLevel
        return acc
      }, {} as Record<string, number>) || {}

    return {
      _id: node?.profileId,
      countryName: node?.title,
      _locked: node?.blocksCollection === null,
      ...riskProperties,
    }
  })

  return transformedData?.sort((a, b) => {
    if (a._locked && !b._locked) return 1
    if (!a._locked && b._locked) return -1
    return a.countryName.localeCompare(b.countryName)
  })
}
