import { useQuery } from '@apollo/client'
import type { UseCountryRisks } from './types'
import { GET_COUNTRY_RISKS } from '../../../queries/getCountryRisks'
import { transformCountryRisks } from '../../transformCountryRisks'
import { LIMIT } from './constants'

export const useCountryRisks = (): UseCountryRisks => {
  const { data, error, loading } = useQuery(GET_COUNTRY_RISKS, {
    variables: {
      limit: LIMIT,
    },
    // indicates which api to use
    context: { apiName: 'sylveraContentful' },
  })

  const transformedData = data ? transformCountryRisks(data) : undefined
  return {
    data: transformedData,
    error,
    loading: loading && !transformedData,
  }
}
