import { Label } from '@sylveraio/design-system/server'
import type { FC } from 'react'
import type { RiskLabelProps } from './types'
import { riskLevelColorMap } from './constants'

export const RiskLabel: FC<RiskLabelProps> = ({
  testId = 'risk-label',
  riskLevel,
}) => (
  <Label
    testId={testId}
    label={riskLevel}
    color={riskLevelColorMap[riskLevel][0]}
    strong={riskLevelColorMap[riskLevel][1]}
  />
)
