import type { SylveraRating } from 'types'
import { getProfileStatus } from '../hooks/useMapData/utils/getProfileStatus'
import type { CountryProfile } from '../transformCountryProfiles/types'
import type { TransformedCountryRiskResult } from '../../containers/CountryRiskTile/types'
import { getRatingFromString } from '@sylveraio/react-utils'
import millify from 'millify'

export const transformProfileAndRiskData = (
  dataForAllCountries: CountryProfile[],
  countryRiskData: TransformedCountryRiskResult,
) =>
  dataForAllCountries?.map((node: CountryProfile) => {
    return (
      {
        ...node,
        profileStatus: getProfileStatus(countryRiskData, node?.isoAlpha2Code),
        estimatedCreditsSupply:
          node?.estimatedCreditsSupply === null
            ? 0
            : node?.estimatedCreditsSupply,
        estimatedCreditsSupplyMillified: node?.estimatedCreditsSupply
          ? millify(node?.estimatedCreditsSupply)
          : undefined,
        permittedCreditsMillified: node?.permittedCredits
          ? millify(node?.permittedCredits)
          : undefined,
        averageRatingNumeric:
          node?.ratedProjectsCount &&
          node?.ratedProjectsCount >= 3 &&
          node?.sylveraRatingAverage
            ? getRatingFromString(node?.sylveraRatingAverage as SylveraRating) +
              1
            : 0,
      } || []
    )
  })
