import { type FC } from 'react'
import type { CountryRisk } from '../RiskTable/types'
import { Tooltip, Typography } from '@sylveraio/design-system'
import InfoCircle from '@sylveraio/untitled-ui-icons-react/build/esm/InfoCircle'
import { RISK_TOOLTIP_MAPPING } from './constants'
import { RiskTableHeaderProps } from './types'

const TooltipContent: FC<{ risk: CountryRisk }> = ({ risk }) => (
  <div className="flex max-w-72 flex-col space-y-2 rounded-lg bg-high p-2 shadow-lg">
    <Typography size="sm" weight="semibold">
      {RISK_TOOLTIP_MAPPING?.[risk].title}
    </Typography>
    <Typography size="sm" weight="regular">
      {RISK_TOOLTIP_MAPPING?.[risk].content}
    </Typography>
  </div>
)

export const RiskTableHeader: FC<RiskTableHeaderProps> = ({
  risk,
  testId = 'country-risk-header',
}) => {
  if (typeof risk === 'undefined') return null

  return (
    <div className="flex" data-testid={`${testId}-${risk}`}>
      {RISK_TOOLTIP_MAPPING?.[risk].header}
      <Tooltip
        placement="bottom-start"
        content={<TooltipContent risk={risk} />}
      >
        <InfoCircle className="ml-1 w-4" />
      </Tooltip>
    </div>
  )
}
