import { type FC } from 'react'
import type { CountryMapPopupProps } from './types'
import { Button, Label, Typography } from '@sylveraio/design-system'
import BookOpen01 from '@sylveraio/untitled-ui-icons-react/build/esm/BookOpen01'
import Download04 from '@sylveraio/untitled-ui-icons-react/build/esm/Download04'
import LockUnlocked01 from '@sylveraio/untitled-ui-icons-react/build/esm/LockUnlocked01'
import { EMPTY_CONTENT, NA_CONTENT } from '../../constants'
import { BUTTON_VARIANT_MAPPING, MODAL_CONSTANTS } from './constants'
import clsx from 'clsx'

export const CountryMapPopup: FC<CountryMapPopupProps> = ({
  countryData,
  hasProfile,
  disabled,
  onClick,
}) => {
  if (typeof countryData === 'undefined') return null

  const iconComponent = () => {
    switch (profileStatus) {
      case 'available':
        return <BookOpen01 />
      case 'pending':
        return <Download04 />
      case 'locked':
        return <LockUnlocked01 />
    }
  }

  const {
    name,
    allProjectsCount,
    averageRatingNumeric,
    sylveraRatingAverage,
    ratedProjectsCount,
    permittedCreditsMillified,
    estimatedCreditsSupplyMillified,
    isoAlpha2Code,
    profileStatus,
  } = countryData

  const titleStyle = 'w-28'
  const sectionStyle = 'flex flex-col border-b border-b-default pb-1 space-y-2'
  const rowStyle = 'flex flex-row'
  const secondaryTheme =
    'focus-visible:border-default focus-visible:bg-medium focus-visible:text-default'
  const primaryTheme = 'focus-visible:bg-content-action-default'
  return (
    <div className="flex w-52 flex-col space-y-2 bg-high p-0">
      <div className="flex flex-row items-center space-x-2 border-b border-b-default pb-1">
        <Typography size="xs" weight="semibold">
          {name}
        </Typography>
        {hasProfile && (
          <div data-testid="icon" className="h-4 w-4">
            <BookOpen01 />
          </div>
        )}
      </div>
      <div className={sectionStyle}>
        <div className={rowStyle}>
          <Typography size="xs" weight="medium" className={titleStyle}>
            {MODAL_CONSTANTS.ALL_PROJECTS}
          </Typography>
          <Typography size="xs" weight="regular" className="!text-subtle">
            {allProjectsCount}
          </Typography>
        </div>
        <div className={rowStyle}>
          <Typography size="xs" weight="medium" className={titleStyle}>
            {MODAL_CONSTANTS.ISSUED}
          </Typography>
          <Typography size="xs" weight="regular" className="!text-subtle">
            {`${permittedCreditsMillified || EMPTY_CONTENT}`}
          </Typography>
        </div>
        <div className={rowStyle}>
          <Typography size="xs" weight="medium" className={titleStyle}>
            {MODAL_CONSTANTS.SUPPLY}
          </Typography>
          <Typography size="xs" weight="regular" className="!text-subtle">
            {`${estimatedCreditsSupplyMillified || EMPTY_CONTENT}`}
          </Typography>
        </div>
      </div>
      <div className={sectionStyle}>
        <div className={rowStyle}>
          <Typography className={titleStyle} size="xs" weight="medium">
            {MODAL_CONSTANTS.AVERAGE_RATING}
          </Typography>
          <Typography size="xs" weight="regular" className="!text-subtle">
            {(averageRatingNumeric > 0 && sylveraRatingAverage) || NA_CONTENT}
          </Typography>
        </div>
        <div className={rowStyle}>
          <Typography className={titleStyle} size="xs" weight="medium">
            {MODAL_CONSTANTS.RATED_PROJECTS}
          </Typography>
          <Typography size="xs" weight="regular" className="!text-subtle">
            {ratedProjectsCount || NA_CONTENT}
          </Typography>
        </div>
      </div>

      {typeof profileStatus !== 'undefined' && profileStatus === 'locked' && (
        <div className={'flex flex-col space-y-2'}>
          <div className={rowStyle}>
            <Typography className={titleStyle} size="xs" weight="medium">
              {MODAL_CONSTANTS.COUNTRY_PROFILE}
            </Typography>
            <Label
              color="neutral"
              label={BUTTON_VARIANT_MAPPING[profileStatus].text}
              size="sm"
              iconLeading={iconComponent()}
              onClick={() => onClick?.(isoAlpha2Code, profileStatus, name)}
            />
          </div>
        </div>
      )}
      {typeof profileStatus !== 'undefined' && profileStatus !== 'locked' && (
        <Button
          iconLeading={iconComponent()}
          label={BUTTON_VARIANT_MAPPING[profileStatus].text}
          variant={BUTTON_VARIANT_MAPPING[profileStatus].theme}
          disabled={disabled}
          testId={`${profileStatus}-icon`}
          className={clsx(
            'w-full focus-visible:ring-transparent',
            BUTTON_VARIANT_MAPPING[profileStatus].theme === 'primary'
              ? primaryTheme
              : secondaryTheme,
          )}
          size="xs"
          onClick={() => onClick?.(isoAlpha2Code, profileStatus, name)}
        />
      )}
    </div>
  )
}
