export const DEFAULT_ORDER = [
  'tile-market-commentary',
  'tile-ratings',
  'tile-countries-map',
  'tile-countries-risk-table',
  'tile-analytics',
  'tile-retirements-trends',
  'tile-insights',
  'tile-box',
]
