import { useEffect, useRef, useState } from 'react'
import { useFilteredArticles } from '../../utils/hooks/useFilteredArticles'
import { FC } from 'react'
import { MarketCommentaryTileTagFilterForYou } from './constants'
import { useTransformArticles } from '../../utils/hooks/useTransformArticles'
import { sendAnalyticsEvent } from '@sylveraio/react-utils'
import type { MarketCommentaryTileArticlesProps } from './types'
import { EmptyState, NewsListItem } from '@sylveraio/design-system'
import Link from 'next/link'
import Settings04 from '@sylveraio/untitled-ui-icons-react/build/esm/Settings04'

export const MarketCommentaryTileArticles: FC<
  MarketCommentaryTileArticlesProps
> = ({ articles, handleEmptyState, selectedFilters, selectedFilter }) => {
  const [showGradient, setShowGradient] = useState(true)
  const bottomRef = useRef<HTMLDivElement>(null)

  const filteredArticles = useFilteredArticles(
    selectedFilters,
    8,
    selectedFilter !== MarketCommentaryTileTagFilterForYou,
  )

  const policies = useTransformArticles(
    selectedFilter === MarketCommentaryTileTagFilterForYou
      ? filteredArticles
      : articles,
  )

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0]
        setShowGradient(!entry.isIntersecting)
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      },
    )

    if (bottomRef.current) {
      observer.observe(bottomRef.current)
    }

    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current)
      }
    }
  }, [])

  return (
    <div className="relative">
      {policies?.length === 0 ? (
        <EmptyState
          icon={<Settings04 className="w-6 h-6" />}
          title="No articles found"
          subtitle="Adjust your filters to see more articles"
          functionalActionName="Customize your feed"
          functionalActionOnClick={handleEmptyState}
          withContainer={false}
          className="h-72"
        />
      ) : (
        policies?.map(
          (policy) =>
            typeof policy.linkTo === 'string' && (
              <Link
                key={policy.id}
                href={policy.linkTo}
                onClick={() => {
                  sendAnalyticsEvent({
                    eventName: 'market-commentary-tile-article-open',
                    eventData: {
                      articleId: policy.id,
                      articleTitle: policy.title,
                      articleTags: policy.tags,
                      customisedFeedFilters: selectedFilters,
                    },
                  })
                }}
              >
                <NewsListItem
                  variant="on-background-high"
                  img={policy?.img}
                  date={policy.date}
                  tags={policy?.tags}
                  title={policy.title}
                  summaryHeading="Sylvera's take"
                  summary={policy.summary}
                />
              </Link>
            ),
        )
      )}
      <div ref={bottomRef} className="h-1" />
      {showGradient && (
        <div className="sticky bottom-0 left-0 w-full h-16 bg-gradient-to-t from-white transition-opacity z-50" />
      )}
    </div>
  )
}
