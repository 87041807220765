import { type Settings } from 'react-slick'

export const IMAGES = [
  {
    src: '/images/risk.png',
    alt: 'Countries risks',
  },
  {
    src: '/images/infoStack.png',
    alt: 'Country profile infostack',
  },
]

export const SLIDER_SETTINGS: Settings = {
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  autoplaySpeed: 3500,
  speed: 750,
  infinite: true,
  autoplay: true,
}
