export const RISK_TOOLTIP_MAPPING = {
  deliveryRisk: {
    title: 'Delivery risk',
    content:
      'Risk of disrupted, delayed or non-delivery of credits arising from the project’s host country.',
    header: 'Delivery',
  },
  utilityRisk: {
    title: 'Utility risk',
    content:
      'Risk to what credits can be utilized and are eligible for, associated with the project’s host country.',
    header: 'Utility',
  },
  reputationalRisk: {
    title: 'Reputational risk',
    content:
      'Risk of losing credibility or failing to meet stakeholder expectations due to the reputation of the project’s host country.',
    header: 'Reputational',
  },
  costRisk: {
    title: 'Cost risk',
    content:
      'Risk of facing costs that exceed pre-established budgets or the intended willingness to pay due to uncertain or high project development costs in the host country.',
    header: 'Cost',
  },
}
