import type { ButtonAppearance, ProfileStatus } from './types'

export const MODAL_CONSTANTS = {
  ALL_PROJECTS: 'All projects',
  SUPPLY: 'Est. supply',
  ISSUED: 'Issued credits',
  AVERAGE_RATING: 'Avg. rating',
  RATED_PROJECTS: 'Rated projects',
  COUNTRY_PROFILE: 'Country profile',
}

export const BUTTON_VARIANT_MAPPING: Record<ProfileStatus, ButtonAppearance> = {
  pending: { theme: 'secondary', text: 'Request Country Profile' },
  locked: { theme: 'secondary', text: 'Locked' },
  available: { theme: 'primary', text: 'Open Profile' },
}
