import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Image from 'next/image'
import { IMAGES, SLIDER_SETTINGS } from './constants'

export const Carousel = () => (
  <Slider {...SLIDER_SETTINGS}>
    {IMAGES.map((image) => (
      <div key={image.alt} className="rounded-lg overflow-hidden">
        <Image src={image.src} alt={image.alt} width={306} height={308} />
      </div>
    ))}
  </Slider>
)
