import type { FC } from 'react'
import clsx from 'clsx'
import { Tooltip, Typography } from '@sylveraio/design-system'
import type { TileHeaderProps, TileProps } from './types'

export const TileHeader: FC<TileHeaderProps> = ({
  testId = 'tile',
  fullWidthChild,
  headerControl,
}) => {
  const tileTitle = (
    <div
      className={clsx('flex space-x-3 items-center', {
        'cursor-pointer': !!headerControl?.tooltipContent,
      })}
    >
      <Typography size="base" weight="semibold">
        {headerControl?.title}
      </Typography>
      {headerControl?.subTitle && (
        <Typography size="xs" weight="regular">
          {headerControl?.subTitle}
        </Typography>
      )}
      {headerControl?.titleLabel && headerControl?.titleLabel}
    </div>
  )

  return (
    <>
      <div
        data-testid={`${testId}-header`}
        className={clsx(
          'z-10 flex items-center justify-between pb-4 max-h-14',
          {
            'px-6 pt-4': fullWidthChild,
          },
        )}
      >
        {headerControl?.tooltipContent ? (
          <Tooltip
            placement="bottom-start"
            content={headerControl?.tooltipContent}
          >
            {tileTitle}
          </Tooltip>
        ) : (
          tileTitle
        )}
        {headerControl?.headerActionElement &&
          headerControl?.headerActionElement}
      </div>
    </>
  )
}

export const Tile: FC<TileProps> = ({
  testId = 'tile',
  children,
  width,
  scrollable,
  color = 'white',
  fullWidthChild,
  headerControl,
}) => (
  <section
    className={clsx(
      'text-default rounded-lg bg-high h-[578px] flex flex-col overflow-hidden',
      {
        'px-6 py-4': !fullWidthChild,
        'desktop-sm:col-span-1 col-span-2': width === '50%',
        'col-span-2': width === '100%',
        '!bg-medium': color === 'beige',
        'bg-high hover:outline outline-1 outline-content-neutral-subtle':
          color === 'white',
      },
    )}
    data-testid={testId}
  >
    <TileHeader
      headerControl={headerControl}
      fullWidthChild={fullWidthChild}
      testId={testId}
    />
    <div
      data-testid={`${testId}-content`}
      className={clsx('min-h-[452px] grow', {
        'overflow-x-auto': scrollable,
        'overflow-hidden': !scrollable,
      })}
    >
      {children}
    </div>
  </section>
)
