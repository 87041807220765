import { useState, type FC } from 'react'
import { CountryMap } from '../CountryMap'
import { FILTER_OPTIONS } from './constants'
import type { MapFilter } from '../CountryMap/types'
import { TileFilters } from '../../components/TileFilters'
import { DashboardTileWrapper } from '../../components/DashboardTileWrapper'
import type { CountryMapTileProps } from './types'
import { sendAnalyticsEvent } from '@sylveraio/react-utils'

export const CountryMapTile: FC<CountryMapTileProps> = ({ id, onUnlock }) => {
  const [filter, setSelectedFilter] = useState<MapFilter>(
    'averageRatingNumeric',
  )

  const handleOnFilter = (filter: MapFilter) => {
    setSelectedFilter(filter)

    sendAnalyticsEvent({
      eventName: 'country-map-tile-filter',
      eventData: {
        filterName: filter,
      },
    })
  }

  return (
    <DashboardTileWrapper
      title="All Countries"
      width="50%"
      key="tile-countries-map"
      testId="tile-countries-map"
      appName="country-profiles"
      fullWidthChild
      headerActionElement={
        <TileFilters
          filters={FILTER_OPTIONS}
          onFilter={(filter) => handleOnFilter(filter as MapFilter)}
          selected={filter}
          ignoreDisabled
        />
      }
      id={id}
    >
      {process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN && (
        <CountryMap
          token={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
          filter={filter}
          onUnlock={onUnlock}
        />
      )}
    </DashboardTileWrapper>
  )
}
