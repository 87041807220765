import type { LabelColors } from '@sylveraio/design-system'
import type { RiskLevels } from './types'
import { createColorMap } from './utils/createColorMap'

/**
 * A mapping of risk levels to their corresponding label colors and whether the label be a strong variant or not.
 * The keys represent the risk level, and the values are arrays where the first element is the label color (e.g., 'positive', 'warning', 'alert'),
 * and the optional second element is a boolean indicating whether the label be a strong variant or not.
 *
 * Example:
 * {
 *   '1': ['positive', true],
 *   '2': ['positive', true],
 *   '3': ['positive'],
 *   '4': ['positive'],
 *   '5': ['warning'],
 *   '6': ['warning'],
 *   '7': ['alert'],
 *   '8': ['alert'],
 *   '9': ['alert', true],
 *   '10': ['alert', true]
 * }
 */
export const riskLevelColorMap: Record<RiskLevels, [LabelColors, boolean?]> = {
  ...createColorMap(['1', '2'], 'positive', true),
  ...createColorMap(['3', '4'], 'positive'),
  ...createColorMap(['5', '6'], 'warning'),
  ...createColorMap(['7', '8'], 'alert'),
  ...createColorMap(['9', '10'], 'alert', true),
}
