import SearchSm from '@sylveraio/untitled-ui-icons-react/build/esm/SearchSm'
import Reflect02 from '@sylveraio/untitled-ui-icons-react/build/esm/Reflect02'
import AlertTriangle from '@sylveraio/untitled-ui-icons-react/build/esm/AlertTriangle'
import Announcement02 from '@sylveraio/untitled-ui-icons-react/build/esm/Announcement02'
import type { OfferingSection } from './types'

export const INTRODUCTION =
  'Access expert analysis on key jurisdictions to help you narrow down the carbon credit universe. Sylvera’s policy team interprets the latest policy and regulatory developments, keeping you one step ahead.'

export const PRODUCT_OFFERINGS_SUMMARY: Array<OfferingSection> = [
  {
    icon: SearchSm,
    title: 'Identify new opportunities',
    description: 'Find jurisdictions that match your risk appetite.',
  },
  {
    icon: Reflect02,
    title: 'Compare across countries',
    description:
      'Make strategic decisions by comparing risks between countries.',
  },
  {
    icon: AlertTriangle,
    title: 'Mitigate country risks',
    description:
      'Minimize the impact of country-specific delivery, utility, reputational, and cost risks.',
  },
  {
    icon: Announcement02,
    title: 'Stay ahead of changes',
    description:
      'Monitor country developments, keeping your strategy up to date.',
  },
]
