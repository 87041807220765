import type { BreakdownDoughnutData } from './types'
import type { RetirementsTrendsTransformed } from '../../../services/transformers/transformRetirementsTrends/types'
import { useEffect, useState } from 'react'
import { getObjectValuesSum } from '@sylveraio/react-utils'
import type { TrendsPerformanceCategory } from '../../../containers/RetirementsTrendsTile/types'
import capitalize from 'lodash/capitalize'
import { LOWER_CASE_LABELS } from './constants'

/**
 * This function takes retirements trends data and transforms it into data format accepted by the Doughnut chart.
 * It inserts additional object with Other as key and remaining percentage as a value
 * @param {RetirementsTrendsTransformed} retirementsTrendsData - The retirements trend object to be used.
 * @param {TrendsPerformanceCategory} groupBy - What the data is groupedBy
 * @returns {BreakdownDoughnutData} - Returns a BreakdownDoughnutData.
 */

export function useRetirementsTrendsChartData(
  retirementsTrendsData?: RetirementsTrendsTransformed,
  groupBy?: TrendsPerformanceCategory,
): BreakdownDoughnutData {
  const [retirementsBreakdown, setRetirementsBreakdown] =
    useState<BreakdownDoughnutData>([])

  useEffect(() => {
    if (typeof retirementsTrendsData?.analysis !== 'undefined') {
      const analyticsValue =
        getObjectValuesSum(retirementsTrendsData?.analysis) || 0

      const formattedRetirementsTrends = Object.entries(
        retirementsTrendsData?.analysis,
      ).map(([key, value]) => ({
        label:
          groupBy === 'Project types' && !LOWER_CASE_LABELS.includes(key)
            ? key.toUpperCase()
            : capitalize(key),
        value,
      }))

      setRetirementsBreakdown(
        analyticsValue < 100
          ? [
              ...formattedRetirementsTrends,
              {
                label: 'Other',
                value: 100 - analyticsValue,
              },
            ]
          : [...formattedRetirementsTrends],
      )
    } else {
      setRetirementsBreakdown([])
    }
  }, [retirementsTrendsData])

  return retirementsBreakdown
}
