import { type FC } from 'react'
import { useRiskTableColumns } from '../../utils/hooks/useRiskTableColumns'
import { RiskTable } from '../../components/RiskTable'
import { RiskLegend } from '../../components/RiskLegend'
import { DashboardTileWrapper } from '../../components/DashboardTileWrapper'
import { CountryProfilesLockedContent } from '../../components/CountryProfilesLockedContent'
import type { CountryRiskTileProps } from './types'
import { useCountryRisks } from '../../utils/hooks/useCountryRisks'
import type { TransformedCountryRisk } from '../../utils/transformCountryRisks/types'
import { isLockedState } from '../../utils/isLockedState'
import { FindOutMore } from '../../components/FindOutMore'
import { sendAnalyticsEvent } from '@sylveraio/react-utils'

const APP_NAME = 'country-profiles'
export const CountryRiskTile: FC<CountryRiskTileProps> = ({ id, onUnlock }) => {
  const { data, loading } = useCountryRisks()
  const formattedColumns = useRiskTableColumns()

  const requestAppAccess = () =>
    sendAnalyticsEvent({
      eventName: 'dashboard-request-access',
      eventData: {
        appName: APP_NAME,
      },
    })

  const handleRequestAccess = () => {
    requestAppAccess()
    if (onUnlock) onUnlock(APP_NAME)
  }

  return (
    <DashboardTileWrapper
      title={
        !loading && isLockedState(data)
          ? 'Introducing Country Profiles'
          : 'Country Risk'
      }
      width="50%"
      key="tile-countries-risk-table"
      testId="tile-countries-risk-table"
      appName="country-profiles"
      id={id}
      headerActionElement={<RiskLegend />}
      locked={!loading && isLockedState(data)}
      showFindOutMore
      onUnlock={onUnlock}
    >
      {!loading && isLockedState(data) ? (
        <div className="flex flex-col justify-between h-full">
          <CountryProfilesLockedContent />
          <FindOutMore onClick={handleRequestAccess} />
        </div>
      ) : (
        <RiskTable
          columns={formattedColumns}
          data={data as TransformedCountryRisk[]}
          onUnlock={onUnlock}
          loading={loading}
        />
      )}
    </DashboardTileWrapper>
  )
}
