import { isValidElement, type FC, useMemo } from 'react'
import type { DashboardWrapperList, DashboardWrapperProps } from './types'
import { DashboardTileWrapper } from '../../components/DashboardTileWrapper'
import { usePreferences } from '@sylveraio/data'
import { TileContainer } from '../../components/TileContainer'
import difference from 'lodash/difference'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { DEFAULT_ORDER } from './constants'
import type { DashboardTileWrapperProps } from '../../components/DashboardTileWrapper/types'

export const DashboardWrapper: FC<DashboardWrapperProps> = ({ tiles }) => {
  const { dragAndDropTiles } = useFlags()

  const {
    data: tileOrder,
    isFetching,
    upsertPreference,
  } = usePreferences('dashboard_tiles_order')

  // Only mutates if tiles mutates
  const elements = useMemo(() => {
    const tileEles: DashboardWrapperList = {}
    tiles.forEach((tile) => {
      if (
        typeof tile.component !== 'undefined' &&
        isValidElement(tile.component)
      ) {
        tileEles[tile.id] = tile.component
      }

      if (typeof tile.props !== 'undefined') {
        tileEles[tile.id] = (
          <DashboardTileWrapper
            key={tile.id}
            {...(tile.props as DashboardTileWrapperProps)}
          />
        )
      }
    })
    return tileEles
  }, [tiles])

  // Only mutates if tiles or tile order mutates, this one is more likely to mutate, as a result of tileOrder
  const Tiles = useMemo(() => {
    const elementIds = Object.keys(elements)
    // Need to make the below understand that if preference = dashboard-tiles-order, then payload is tiles
    // @ts-ignore
    const storedOrderVals: Array<string> | undefined = tileOrder?.value?.tiles
    if (typeof storedOrderVals === 'undefined' || !dragAndDropTiles)
      return DEFAULT_ORDER.map((id) => elements[id])
    // Capture tiles added since the last time the user's preference was updated
    const notInStoredOrderVals = difference(elementIds, storedOrderVals)
    // Extract IDs in the new order
    const newOrderIds = [...storedOrderVals, ...notInStoredOrderVals]

    if (notInStoredOrderVals.length > 0) {
      // Write back to API if there were tiles missing in their payload
      if (upsertPreference) upsertPreference({ tiles: newOrderIds })
    }

    return newOrderIds.map((id) => elements[id])
  }, [elements, tileOrder, dragAndDropTiles])

  return <TileContainer isFetching={isFetching} tiles={Tiles} />
}
