import type { TransformedCountryRiskResult } from '../../containers/CountryRiskTile/types'
import type { LockedState } from '../transformCountryRisks/types'

/**
 * The isLockedState function checks if the given data is in a locked state by determining if it contains a _locked property
 * @param {TransformedCountryRisk[]} data data to evaluate
 * @returns {boolean} - indication if given data is in a locked state
 */

export const isLockedState = (
  data: TransformedCountryRiskResult,
): data is LockedState => {
  return (data as LockedState)?._locked !== undefined
}
