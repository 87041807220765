'use-client'
import { type FC, useState } from 'react'
import {
  DISCLAIMER,
  GROUP_BY_MAPPING,
  GROUP_BY_OPTIONS,
  GROUP_BY_OPTIONS_TOOLTIPS,
  LABEL_STYLING,
  LAYOUT_STYLING,
} from './constants'
import type {
  RetirementsTrendsTileProps,
  TrendsPerformanceCategory,
} from './types'
import { getAppUrl } from '@sylveraio/react-utils'
import { useRetirementsTrends } from '../../utils/hooks/useRetirementsTrends'
import { useRetirementsTrendsChartData } from '../../utils/hooks/useRetirementsTrendsChartData'
import { BreakdownDoughnut } from '@sylveraio/react-ui'
import { TileFilters } from '../../components/TileFilters'
import { DashboardTileWrapper } from '../../components/DashboardTileWrapper'

export const RetirementsTrendsTile: FC<RetirementsTrendsTileProps> = ({
  locked = false,
  onUnlock,
  id,
}) => {
  const [selectedPill, setSelectedPill] =
    useState<TrendsPerformanceCategory>('Project types')

  const { data, isFetching } = useRetirementsTrends(
    {},
    GROUP_BY_MAPPING[selectedPill],
  )

  const retirementsTrendsBreakdownData = useRetirementsTrendsChartData(
    data,
    selectedPill,
  )

  const handleSelectedGroupBy = (performanceCategory: string) =>
    setSelectedPill(performanceCategory as TrendsPerformanceCategory)

  return (
    <DashboardTileWrapper
      title="Retirement Breakdown"
      width="50%"
      key="tile-retirements-trends"
      testId="tile-retirements-trends"
      href={`${getAppUrl('analytics')}/market-overview`}
      locked={locked}
      appName="analytics"
      onUnlock={onUnlock}
      id={id}
    >
      <TileFilters
        filters={GROUP_BY_OPTIONS}
        onFilter={handleSelectedGroupBy}
        selected={selectedPill}
        testId={'absolute tile-retirements-trends-filters'}
        filtersClassName={'!mb-0 !pb-0 -mt-4 py-4'}
        filtersTooltipMapping={GROUP_BY_OPTIONS_TOOLTIPS}
        ignoreDisabled
      />
      <div className="flex w-full items-end justify-center mt-6">
        <BreakdownDoughnut
          colorScheme="rainbow"
          loading={isFetching}
          data={retirementsTrendsBreakdownData}
          wrapperClassName="h-[430px] w-[500px]"
          layoutStyling={LAYOUT_STYLING}
          labelStyling={LABEL_STYLING}
        />
      </div>
      <div className="text-xs text-subtle">{DISCLAIMER}</div>
    </DashboardTileWrapper>
  )
}
