import { isLockedState } from '../../../../../utils/isLockedState'
import type { ProfileStatus } from '../../../../../components/CountryMapPopup/types'
import type {
  LockedState,
  TransformedCountryRisk,
} from '../../../../../utils/transformCountryRisks/types'

/**
 * getProfileStatus function determines the status of a country profile based on the provided profile data and ISO Alpha-2 code
 * @param {TransformedCountryRisk[] | LockedState | undefined} permissioned country risk data
 * @param {string} isoAlpha2Code - A string representing the isoAlpha2Code
 * @returns {ProfileStatus} - A profile status that can be'profileLocked', 'requestProfile', or 'openProfile'
 */

export const getProfileStatus = (
  profileData: TransformedCountryRisk[] | LockedState | undefined,
  isoAlpha2Code?: string,
): ProfileStatus => {
  if (isLockedState(profileData)) return 'locked'

  const profile = profileData?.find((profile) => profile._id === isoAlpha2Code)

  if (!profile) {
    return 'pending'
  } else if (profile._locked) {
    return 'locked'
  } else {
    return 'available'
  }
}
