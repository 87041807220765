'use client'

import { type FC, useState } from 'react'
import clsx from 'clsx'
import { Spinner, Table } from '@sylveraio/design-system'
import type { RiskTableProps } from './types'
import { COLUMNS_STYLING } from './constants'
import { sendAnalyticsEvent } from '@sylveraio/react-utils'
import { SortingState } from '@tanstack/react-table'

export const RiskTable: FC<RiskTableProps> = ({
  testId,
  data,
  columns,
  loading,
  onUnlock,
}) => {
  const [selectedRow, setSelectedRow] = useState<string | undefined>()

  if (typeof data === 'undefined') return

  const handleOnSort = (sorting: SortingState) => {
    sendAnalyticsEvent({
      eventName: 'country-profiles-risk-table-tile-sort',
      eventData: {
        columnName: sorting[0].id,
        sortDirection: sorting[0].desc ? 'desc' : 'asc',
      },
    })
  }

  const handleSelectRow = (
    value: string,
    rowData?: Record<string, unknown>,
  ) => {
    if (!value) return

    if (rowData?._locked) {
      sendAnalyticsEvent({
        eventName: 'country-profiles-risk-table-tile-row-request-profile-click',
        eventData: {
          countryName: rowData?.countryName,
        },
      })

      onUnlock?.(
        'country-profiles',
        {
          countryProfileName: rowData?.countryName as string,
        },
        'country',
      )
      return
    }
    sendAnalyticsEvent({
      eventName: 'country-profiles-risk-table-tile-open-profile-click',
      eventData: {
        countryName: rowData?.countryName as string,
      },
    })
    setSelectedRow(value)
    window.open(`profile/country/${value}`, '_blank')
  }

  if (loading)
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Spinner />
      </div>
    )

  return (
    <Table
      testId={testId}
      columns={columns}
      dataset={data}
      className="overflow-auto max-h-[470px] mt-4"
      tableClassName="desktop-md:table-fixed"
      headerClassName="sticky top-[0px] bg-high z-40 h-12 !text-subtle border-b border-strong"
      rowClassName={clsx(
        'h-14 border-b-[0.5px] border-default last:!border-b-0 hover:bg-medium',
      )}
      columnClassName={{ ...COLUMNS_STYLING }}
      sortControls={{
        onSortChange: handleOnSort,
        sortField: 'countryName',
        sortDirection: 'asc',
        sortArrowVariant: 'singleArrow',
      }}
      selectedRow={{
        key: '_id',
        // @ts-ignore
        value: selectedRow,
        onClick: handleSelectRow,
        className: 'focus:bg-none',
      }}
      defaultRowCount={25}
    />
  )
}
