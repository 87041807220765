'use client'

import type { FC } from 'react'
import Image from 'next/image'
import type { TileImageProps } from './types'

export const TileImage: FC<TileImageProps> = ({ url, alt }) => (
  <div className="relative w-full h-full">
    <Image src={url} alt={alt} fill className="object-cover object-top" />
  </div>
)
