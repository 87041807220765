import { AppLogo, AppOrResourceName } from '@sylveraio/layouts'
import type { FC } from 'react'
import Link from 'next/link'
import clsx from 'clsx'
import { ContentShadow, Modal } from '@sylveraio/react-ui'
import { sendAnalyticsEvent } from '@sylveraio/react-utils'
import { Tile } from '../Tile'
import { TILE_TOOLTIP_MSG_MAP } from '../../constants'
import type { DashboardTileWrapperProps } from './types'
import { Label, RequestMoreInfoButton } from '@sylveraio/design-system'
import Lightning01 from '@sylveraio/untitled-ui-icons-react/build/esm/Lightning01'

const TooltipContent: FC<{ appName: AppOrResourceName; testId?: string }> = ({
  appName,
  testId,
}) => {
  if (
    appName === 'projects' ||
    appName === 'country-map' ||
    appName === 'country-risk'
  )
    return null

  return (
    <Modal
      className={clsx(
        'whitespace-normal !bg-transparent !p-0 !m-0 !rounded-lg',
      )}
      removeShadow
    >
      <div tabIndex={2}>
        <ContentShadow
          wrapper
          className="w-[420px] !p-6"
          bgColor="bg-high"
          wrapperClassName="flex flex-col space-y-2"
        >
          <AppLogo appName={appName} size="small" />
          <p
            className="font-maison text-base"
            data-testid={`${testId}-tooltip-msg`}
          >
            {TILE_TOOLTIP_MSG_MAP?.[appName]}
          </p>
        </ContentShadow>
      </div>
    </Modal>
  )
}

export const DashboardTileWrapper: FC<DashboardTileWrapperProps> = ({
  children,
  id,
  width = '50%',
  onUnlock,
  locked,
  showFindOutMore,
  title,
  subTitle,
  testId = 'tile-wrapper',
  href,
  scrollable,
  appName,
  fullWidthChild,
  headerActionElement,
  onSeeAllClick,
}) => {
  const onGoToApp = () =>
    sendAnalyticsEvent({
      eventName: 'dashboard-go-to-app',
      eventData: {
        appName,
      },
    })

  const requestAppAccess = () =>
    sendAnalyticsEvent({
      eventName: 'dashboard-request-access',
      eventData: {
        appName,
        tileId: id,
      },
    })

  const handleRequestAccess = () => {
    requestAppAccess()
    if (onUnlock && appName && appName !== 'market-commentary')
      onUnlock(appName)
  }

  const headerComponent = () => {
    switch (appName) {
      case 'market-commentary':
      case 'ratings':
      case 'analytics':
      case 'insights':
      case 'box':
        return (
          href && (
            <Link
              href={href}
              className="pointer text-sm underline underline-offset-2 hover:no-underline"
              onClick={onSeeAllClick || onGoToApp}
              data-testid={`${testId}-see-all`}
            >
              See all
            </Link>
          )
        )
      case 'country-profiles':
        return headerActionElement
      default:
        return null
    }
  }

  return (
    <Tile
      width={width}
      scrollable={scrollable}
      color={locked ? 'beige' : 'white'}
      fullWidthChild={fullWidthChild}
      headerControl={{
        headerActionElement: !locked
          ? headerComponent()
          : !showFindOutMore && (
              <RequestMoreInfoButton onClick={handleRequestAccess} />
            ),
        title: title,
        titleLabel: locked && (
          <Label label="Add-on" outlined iconLeading={<Lightning01 />} strong />
        ),
        subTitle: subTitle,
        tooltipContent: appName && <TooltipContent appName={appName} />,
      }}
    >
      {children}
    </Tile>
  )
}
