import type { FC } from 'react'
import { Typography } from '@sylveraio/design-system/server'
import { RISK_LEVELS } from '../../constants/server'
import { RiskLabel } from '../RiskLabel'
import { HIGH_RISK, LOW_RISK } from './constants'

export const RiskLegend: FC<{ testId?: string }> = ({
  testId = 'risk-legend',
}) => {
  const legendLabelStyle = 'text-subtle'

  return (
    <div data-testid={testId} className="flex w-fit flex-col space-y-1">
      <div className="flex flex-row space-x-0.5">
        {RISK_LEVELS.map((riskLevel) => {
          return <RiskLabel key={riskLevel} riskLevel={riskLevel} />
        })}
      </div>
      <div className="flex w-full flex-row justify-between">
        <Typography size="xs" className={legendLabelStyle} weight="regular">
          {LOW_RISK}
        </Typography>
        <Typography size="xs" className={legendLabelStyle} weight="regular">
          {HIGH_RISK}
        </Typography>
      </div>
    </div>
  )
}

export default RiskLegend
