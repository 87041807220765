'use client'

import { type FC, useEffect } from 'react'
import { HUBSPOT_IDS } from '@sylveraio/auth-utils'
import type { RequestMoreInfoFormProps } from './types'
import { RequestSubmittedModal } from '@sylveraio/react-ui'
import { CountryProfilesLockedModal } from '../CountryProfilesLockedModal'
import { useHubspotFormSubmit } from '../../utils/hooks/useHubspotFormSubmit'

export const RequestMoreInfoForm: FC<RequestMoreInfoFormProps> = ({
  onClose,
  appName,
  formData,
}) => {
  const {
    submitting,
    success,
    showModal,
    setShowModal,
    handleSubmit,
    handleClose,
  } = useHubspotFormSubmit({
    formData: {
      data: formData.data,
      id: HUBSPOT_IDS[appName] as string,
      additionalData: formData?.additionalData,
    },
    onClose,
  })

  const { data, variant } = formData

  useEffect(() => {
    if (typeof data === 'undefined' || typeof appName === 'undefined') return

    if (variant === 'country') {
      setShowModal(true)
      return
    }

    handleSubmit()
  }, [data, variant])

  return (
    <>
      {showModal && (
        <CountryProfilesLockedModal
          onClose={handleClose}
          onSubmit={handleSubmit}
          isSubmitting={submitting}
        />
      )}
      {success && !submitting && (
        <RequestSubmittedModal onClose={handleClose} />
      )}
    </>
  )
}
