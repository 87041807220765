import { type FC, useEffect } from 'react'
import type { RequestCountryAssessmentFormProps } from './types'
import { RequestSubmittedModal } from '@sylveraio/react-ui'
import { FORM_ID, MODAL_CONTENT } from './constants'
import { RequestAssessmentModal } from './components/RequestCountryAssessmentForm'
import { useHubspotFormSubmit } from '../../utils/hooks/useHubspotFormSubmit'

export const RequestCountryAssessmentForm: FC<
  RequestCountryAssessmentFormProps
> = ({ onClose, formData }) => {
  const {
    submitting,
    success,
    showModal,
    setShowModal,
    handleSubmit,
    handleClose,
  } = useHubspotFormSubmit({
    formData: {
      data: formData.data,
      id: FORM_ID,
      additionalData: formData?.additionalData,
    },
    onClose,
  })

  const { data, variant } = formData

  useEffect(() => {
    if (
      typeof data === 'undefined' ||
      typeof formData?.additionalData?.countryProfileName === 'undefined'
    )
      return

    if (variant === 'countryAssessment') {
      setShowModal(true)
      return
    }
  }, [data, variant])

  return (
    <>
      {showModal && (
        <RequestAssessmentModal
          onClose={handleClose}
          onSubmit={handleSubmit}
          countryName={formData?.additionalData?.countryProfileName}
          isSubmitting={submitting}
        />
      )}
      {success && !submitting && (
        <RequestSubmittedModal
          onClose={handleClose}
          modalContentOverwrite={`${formData?.additionalData?.countryProfileName} ${MODAL_CONTENT}`}
        />
      )}
    </>
  )
}
