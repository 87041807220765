import { getRatingFromString } from '@sylveraio/react-utils'
import millify from 'millify'
import { useMemo } from 'react'
import { useCountryProfiles } from '../useCountryProfiles'
import type { UseMapData } from './types'
import type { CountryProfile } from '../../transformCountryProfiles/types'
import type { SylveraRating } from 'types'
import { useCountryRisks } from '../useCountryRisks'
import { getProfileStatus } from './utils/getProfileStatus'
import isEmpty from 'lodash/isEmpty'
import { transformProfileAndRiskData } from '../../transformProfileAndRiskData'

/**
 * UseMapData is a function that fetches data from the useCountryProfiles hook and transforms it to a format accepted by the map
 * @returns {UseMapData} - An object containing the data, a boolean value indicating if the data is validating.
 */

export function useMapData(): UseMapData {
  const { data: dataForAllCountries, loading } = useCountryProfiles()
  const { data: countryRiskData, loading: loadingRiskData } = useCountryRisks()
  const isLoading = loading || loadingRiskData
  const combinedData =
    !isLoading && dataForAllCountries && countryRiskData
      ? transformProfileAndRiskData(dataForAllCountries, countryRiskData)
      : undefined

  return {
    data: combinedData,
    isFetching: (loading || loadingRiskData) && isEmpty(combinedData),
  }
}
