import type { LabelColors } from '@sylveraio/design-system'
import type { RiskLevels } from '../../types'

/**
 * Creates a color map for risk levels.
 *
 * @param {RiskLevels[]} levels - An array of risk levels.
 * @param {LabelColors} color - The color to be used for the risk levels.
 * @param {boolean} [strong=false] - Whether to use a strong color variant or not.
 * @returns {Record<RiskLevels, [LabelColors, boolean?]>} An object mapping risk levels to an array containing the color and a boolean indicating whether to use a strong color variant or not.
 */
export function createColorMap(
  levels: RiskLevels[],
  color: LabelColors,
  strong?: boolean,
): Record<RiskLevels, [LabelColors, boolean?]> {
  return levels.reduce((acc, level) => {
    // eslint-disable-next-line no-param-reassign
    acc[level] = [color, strong]
    return acc
  }, {} as Record<RiskLevels, [LabelColors, boolean?]>)
}
