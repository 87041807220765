import { useState } from 'react'
import { submitDashboardTilesHubspotForm } from '../../../services/hubspot/submitDashboardTilesHubspotForm'
import { captureException } from '@sylveraio/react-utils'
import type { UseFormSubmitProps, UseFormSubmit } from './types'

export const useHubspotFormSubmit = ({
  formData,
  onClose,
}: UseFormSubmitProps): UseFormSubmit => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)

  const { data, id, additionalData } = formData

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      await submitDashboardTilesHubspotForm(data, id, additionalData)
      setSuccess(true)
    } catch (e) {
      captureException(e)
      setSuccess(false)
    } finally {
      setShowModal(false)
      setSubmitting(false)
    }
  }

  const handleClose = () => {
    setSuccess(false)
    setShowModal(false)
    onClose?.()
  }

  return {
    submitting,
    success,
    showModal,
    setShowModal,
    handleSubmit,
    handleClose,
  }
}
